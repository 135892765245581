function valid(value) {
  try {
    const __date = new Date(value);
    if (isNaN(__date.getTime())) return undefined;
  } catch (err) {
    return undefined;
  }
  return value;
}

function toDate(value) {
    return typeof value === 'string' ? new Date(value) : value;
}

function shortMonthYear(date) {
  let the_date = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let formatted_date =
    months[the_date.getMonth()] + " " + the_date.getFullYear();
  return date === null || isNaN(the_date.getMonth()) ? "" : formatted_date;
}

function format(date, includeTime = false) {
  try {
    let the_date = new Date(date);
    let formatted_date =
      the_date.getMonth() +
      1 +
      "." +
      the_date.getDate() +
      "." +
      the_date.getFullYear();
    if (includeTime) {
      formatted_date += " " + String(the_date.getHours()).padStart(2, '0') + ":" + String(the_date.getMinutes()).padStart(2, '0');
    }
    return date === null || isNaN(the_date.getMonth()) ? "" : formatted_date;
  } catch (error) {
    return ""
  }
}

function minutesBetween(date1, date2) {
  var diff = (date2.getTime() - date1.getTime()) / 1000 / 60;
  return Math.abs(Math.round(diff));
}

function daysBetween(date1, date2) {
  return Math.floor(minutesBetween(date1, date2) / 60 / 24);
}

function addMinutes(date, number) {
  return new Date(date).setMinutes(new Date(date).getMinutes() + number);
}

function addDays(date, number) {
  return new Date(date).setDate(new Date(date).getDate() + number);
}

function compare(date1, date2, reverse = false) {
  if (date1 < date2) {
    return reverse ? 1 : -1;
  } else if (date1 > date2) {
    return reverse ? -1 : 1;
  }
  return 0;
}

function dayOfYear(date) {
    const startOfYear = new Date(date.getFullYear(), 0, 0); // January 1st of the same year
    const diff = date - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    return Math.floor(diff / oneDay);
}

function yesterday() {
  const today = new Date();
  return new Date(today.getTime() - (24 * 60 * 60 * 1000));
}


module.exports = {
  valid,
  format,
  shortMonthYear,
  minutesBetween,
  addMinutes,
  addDays,
  daysBetween,
  compare,
  toDate,
  dayOfYear,
  yesterday
};
