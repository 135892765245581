import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import Select from "react-select";
import NumberFormat from "react-number-format";

import ConditionalButton from "../conditional-button";
import { deepEqual } from "../../utils/object";
import { invoice } from "../../utils/invoice";
import { pending as getInvoiceItems, update as setInvoice } from "../../actions/invoice";
import { format as formatDate } from "../../utils/date";
import { currency as formatCurrency } from "../../utils/number";

import Table from "../table";

class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 5,
            search: "",
            order: "date",
            index: 0,
            direction: "DESC",
            data: [],
            items: [],
            pages: 0,
            client: null,
            invoice: null
        };
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    fetchData = async () => {
        setTimeout( async () => {
            const __invoices = await this.props.getInvoiceItems(this.props.client, null);
            const __invoice = (new invoice()).load(__invoices);

            this.setState({
                ...this.state,
                data: __invoice.items.services.slice(0, this.state.size),
                all_data: __invoice,
                pages: Math.ceil(__invoice.items.services.length / Number(this.state.size)),
                due: __invoice.totals.due(),
                index: 0,
                direction: "DESC"
            });
        }, 30);
    }

    componentDidUpdate = (old) => {
        if ((this.props.show && !old.show) || !deepEqual(this.props.client, old.client) || !deepEqual(this.props.invoice, old.invoice)) {
            this.fetchData();
        }
    }

    onChange = (e) => {
        const item = this.state.all_data.items.services.find((item) => {
            return (item.uuid === e.uuid);
        });
        item[e.name] = e.value || 0;
        item["net"] = item["gross"] - item["adjustment"];
    
        this.setState({
            ...this.state,
            data: this.state.all_data.items.services.slice(this.state.page * this.state.size, (this.state.page + 1) * this.state.size),
            due: this.state.all_data.totals.due()
        })
    }

    onTrustChange = (e) => {
        this.state.all_data.trust[e.name] = e.value;
        this.setState({
            ...this.state,
            due: this.state.all_data.totals.due()
        });

    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = async (e) => {
        await this.props.setInvoice(this.props.client, this.state.all_data.export());
        this.props.onOk({
        });
    }

    onRemoveExpenses = async (e) => {
        const __invoice = this.state.all_data;
        __invoice.items.services = __invoice.items.services.filter(item => item.type !== "EX");
        this.setState({
                ...this.state,
                data: __invoice.items.services.slice(0, this.state.size),
                all_data: __invoice,
                pages: Math.ceil(__invoice.items.services.length / Number(this.state.size)),
                due: __invoice.totals.due(),
                index: 0,
                direction: "DESC"
        });
        
        console.log(__invoice);
    }

    onRemoveTime = async (e) => {
        const __invoice = this.state.all_data;
        __invoice.items.services = __invoice.items.services.filter(item => item.type !== "CL");
        this.setState({
                ...this.state,
                data: __invoice.items.services.slice(0, this.state.size),
                all_data: __invoice,
                pages: Math.ceil(__invoice.items.services.length / Number(this.state.size)),
                due: __invoice.totals.due(),
                index: 0,
                direction: "DESC"
        });
        
        console.log(__invoice);
    }


    onColumnClick = (e) => {
        const __array = this.headers;
        
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });
        this.state.all_data.items.sort({ sort: e.source, direction: this.state.index === __index ? this.state.direction === "DESC" ? "ASC" : "DESC" : "DESC" });
        
        this.setState({
            ...this.state,
            data: this.state.all_data.items.services.slice(0, this.state.size),
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            page: 0
        });

    }


    onPageChange = (e) => {
        this.setState({
            ...this.state,
            data: this.state.all_data.items.services.slice(e*this.state.size, (e+1)*this.state.size),
            page: Number(e)
        });
    }

    
    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "date",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onDateFormat
            },
            {
                header: "Description",
                sortable: true,
                source: "description",
                className: "text-muted text-small text-uppercase w-60",
                onRender: this.onDescriptionRender
            },
            {
                header: "Gross",
                sortable: true,
                source: "gross",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "-Adj",
                sortable: true,
                source: "adjustment",
                className: "text-muted text-small text-uppercase w-20",
                onRender: this.onCurrencyRender
            },
            {
                header: "=Net",
                sortable: true,
                source: "net",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onDescriptionRender = (e, row) => {
      
        return (<>
            <Form.Control
                type="text"
                name="description"
                value={e || ""}
                onChange={(e) => this.onChange({
                    uuid: row["uuid"],
                    name: "description",
                    value: e.target.value,
                    row: row
                })}

            /></>);
    }

    onCurrencyRender = (e, row) => {
      
        return (<>
            <NumberFormat
                displayType="input"
                type="text"
                id={`adjustment-${row["uuid"]}`}
                name="adjustment"
                className="text-end form-control"
                prefix={"$"}
                thousandSeparator={true}
                allowNegative={false}
                value={e}
                onValueChange={(e) =>
                    this.onChange({
                        uuid: row["uuid"],
                        name: "adjustment",
                        value: e.floatValue,
                        row: row
                    })
                }
                removeFormatting
            /></>);
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number(e));
    }

    onDateFormat = (e) => {
        return formatDate(e, false);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="xl" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Invoice`}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop: "0px"}}>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12">
                            <Table
                                headers={this.headers}
                                page={this.state.data}
                                sort={{ index: this.state.index, direction: this.state.direction }}
                                size={this.state.size}
                                index={this.state.page}
                                pages={this.state.pages}
                                onColumnClick={this.onColumnClick}
                                onPageChange={this.onPageChange}
                            />
                        </Col>
                        <Col xs="1" md="4"></Col>
                        <Col xs="6" md="4">
                            <Row>
                                <Col xs="6" lg="8" className="text-end">Trust Minimum</Col>
                                <Col xs="6" lg="4" className="text-end"><p style={{ marginRight: "20px" }}>
                                    <NumberFormat
                                        displayType="input"
                                        type="text"
                                        id="minimum"
                                        name="minimum"
                                        className="text-end form-control"
                                        prefix={"$"}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={this.state?.all_data?.trust?.minimum}
                                        onValueChange={(e) =>
                                            this.onTrustChange({
                                                name: "minimum",
                                                value: e.floatValue
                                            })
                                        }
                                        removeFormatting
                                    />
                                    </p></Col>
                                <Col xs="6" lg="8" className="text-end">Current</Col>
                                <Col xs="6" lg="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.trust?.current)}</p></Col>
                            </Row>
                        </Col>
                        <Col xs="5" md="4">
                            <Row>
                                <Col xs="8" className="text-end">+ Previous Balance</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.totals?.balance())}</p></Col>
                                <Col xs="8" className="text-end">+ Services</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.totals?.services())}</p></Col>
                                <Col xs="8" className="text-end">Subtotal</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.totals?.subtotal())}</p></Col>
                                <Col xs="8" className="text-end">- Payments</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(-this.state?.all_data?.totals?.payments())}</p></Col>
                                <Col xs="8" className="text-end">Trust Remaining</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.trust?.available())}</p></Col>
                                <Col xs="8" className="text-end">Minimum Payment Due</Col>
                                <Col xs="4" className="text-end"><p style={{ marginRight: "20px" }}>{formatCurrency(this.state?.all_data?.totals?.due())}</p></Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onRemoveExpenses} icon="minus">remove expenses</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onRemoveTime} icon="minus">remove time</ConditionalButton>
                    
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" disabledVariant="outline-light" display enabled={ this.state?.all_data?.items?.services?.length > 0 } onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps, { getInvoiceItems, setInvoice })(ExModal);