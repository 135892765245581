import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";
import { dayOfYear, addDays } from "../../../utils/date";

export function exists(value) {
    return TimeSpanData.filter(obj => { return obj.value === value });
}

export const TimeSpanData = [
    { value: "0,1", label: "Today" },
    { value: "-1,1", label: "Yesterday" },
    { value: "0,7", label: "Last Week" },
    { value: `0,30`, label: "Last 30 Days" },
    { value: `0,${(new Date()).getDate()}`, label: "This Month" },
    { value: `0,${(dayOfYear(new Date()))}`, label: "This Year" },
    { value: "0,3650", label: "Forever" },
];



class TimeSpanSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            const __array = e.value.split(",");
            const endDate = new Date(addDays(new Date(), Number(__array[0])));
            const startDate = new Date(addDays(endDate, -Number(__array[1])));
            
            this.setState(
                {
                    value: {
                        startDate, endDate, label: e.label, value: e.value
                    }
                }, () => { this.props.onChange(this.state.value) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={TimeSpanData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={TimeSpanData}
            >
            </Select>
        );
    }
};

export default TimeSpanSelect;
