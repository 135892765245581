
const data = [
  {
    id: "home",
    icon: "home",
    caption: "Home",
    to: "/dashboard",
    title: "Firm Dashboard",
    description: " Firm facing dashboard and control center",
    display: [],
    permissions: ["CAN_LOGIN_ADMIN"],
    contains: {
      indent: 0,
      items: [
        {
          id: "my_profile",
          icon: "user",
          caption: "My Profile",
          to: "/dashboard/profile/me",
          title: "My Profile",
          description: "Biographical information about you",
          display: ["Sidebar"],
          permissions: ["CAN_LOGIN_ADMIN"],
        },
        {
          id: "firm_leads",
          icon: "phone",
          caption: "Leads",
          to: "/dashboard/leads",
          title: "Leads",
          description: "Lead information",
          display: ["Sidebar", "Mobile"],
          permissions: ["VIEW.PERSONS"],
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_lead",
                  icon: "content",
                  caption: "This Lead",
                  to: "/dashboard/leads/add",
                  title: "New lead",
                  display: [],
                  permissions: ["MODIFY.PERSONS"],
                },
                {
                  id: "edit_lead",
                  icon: "content",
                  caption: "This Lead",
                  to: "/dashboard/leads/:id",
                  title: "Edit lead",
                  display: [],
                  permissions: ["MODIFY.PERSONS"],
                }
              ]
          }
        },
        {
          id: "firm_clients",
          icon: "folder",
          caption: "Clients",
          to: "/dashboard/clients",
          title: "Clients",
          description: "Client information",
          display: ["Sidebar", "Mobile"],
          permissions: ["VIEW.CLIENTS"],
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_client",
                  icon: "content",
                  caption: "This Client",
                  to: "/dashboard/leads/add",
                  title: "New client",
                  display: [],
                  permissions: ["MODIFY.CLIENT"],
                },
                {
                  id: "edit_client",
                  icon: "content",
                  caption: "This Client",
                  to: "/dashboard/clients/:id",
                  title: "Edit client",
                  display: [],
                  permissions: ["MODIFY.CLIENT"],
                }
              ]
          }
        },
        {
          id: "reports",
          icon: "file-chart",
          caption: "Reports",
          to: "/dashboard/reports",
          title: "Reports",
          description: "Report template",
          display: ["Sidebar"],
          permissions: ["VIEW.REPORTS"],
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "add_report",
                  icon: "content",
                  caption: "This Report",
                  to: "/dashboard/reports/add",
                  title: "New report",
                  display: [],
                  permissions: ["VIEW.REPORTS"],
                },
                {
                  id: "edit_report",
                  icon: "content",
                  caption: "This Report",
                  to: "/dashboard/reports/:id",
                  title: "Edit report",
                  display: [],
                  permissions: ["VIEW.REPORTS"],
                }
              ]
          }
        },
        {
          id: "ledger",
          icon: "dollar",
          caption: "Ledger",
          to: "/dashboard/ledger",
          title: "Ledger",
          description: "Accounts Ledger",
          display: ["Sidebar"],
          permissions: ["VIEW.LEDGER"]
        },
      ]
    }
  }
];

export default data;

