import React from "react";
import { Form, Row, Col, Card } from "react-bootstrap";

import Actives from "./active";
import Events from "./events";
import Utilization from "./utilization";

class Summary extends React.Component
{
    render() {
        return (
            <>
                <Col md="8" className="float-right text-end"></Col>
                <Col xs="12" lg="6" xl="4" className="mb-3">
                    <Events history={ this.props.history } />
                </Col>
                <Col xs="12" lg="6" xl="4" className="mb-3">
                    <Utilization />
                </Col>
                <Col xs="12" lg="6" xl="4" className="mb-3">
                    <Actives />
                </Col>
            </>
        )
    }
}

export default Summary;