import React from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { format as formatDateFn } from "date-fns";
import Table from "../../../layout/table";
import { table as getEmployees } from "../../../actions/employee";
import { format as formatDate, yesterday } from "../../../utils/date";
import { currency, format as formatNumber } from "../../../utils/number";
import TimeSpan from "../../../layout/select/timespan";

import ConditionalButton from "../../../layout/conditional-button";

class Utilization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            dateStart: formatDateFn(yesterday(), "yyyy-MM-dd"),
            dateEnd: "2099-12-31",
            label: "Today",
            value: "0,1",
            order: "lastName",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            archived: 0,
            converted: 0,
            past: 0,
            active: 1
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }


    fetchData = async () => {
        const __data = await this.props.getEmployees(this.state.page, this.state.size, this.state.order, this.state.direction, this.state.dateStart, this.state.dateEnd, this.state.search);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-50",
                onRender: this.onNameLinkFormat
            },
            {
                header: "Time",
                sortable: true,
                source: "minutes",
                className: "text-muted text-small text-uppercase w-30",
                onFormat: this.onMinutesFormat
            },
            {
                header: "Value",
                sortable: true,
                source: "value",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onNameLinkFormat = (e, row) => {
        return <a href={`/dashboard/employees/${row['Person.uuid']}`}>{row["Person.lastName"]}, {row["Person.firstName"]}</a>    
    }

    onMinutesFormat = (e, row) => {
        let result = "";
        let __minutes = Number(e);
        if (__minutes > (60 * 24)) {
            const __days = Math.floor(__minutes / 60 / 24);
            __minutes -= __days * 60 * 24;
            result += `${formatNumber(__days, 0, 0, true)} days `; 
        }
        if (__minutes > (60)) {
            const __hours = Math.floor(__minutes / 60 );
            __minutes -= __hours * 60;
            result += `${formatNumber(__hours, 0, 0, true)} hours `; 
        }
        if (__minutes > (0)) {
            result += `${formatNumber(__minutes, 0, 0, true)} minutes `; 
        }
        return result;
    }

    onCurrencyFormat = (e, row) => {
        return currency(Number(e));
    }


    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    onPastChange = (e) => {
        this.setState({
            ...this.state,
            past: this.state.past === 1 ? 0 : 1
        }, this.fetchData);
    }

    onTimeChange = (e) => {
        this.setState({
            ...this.state,
            dateStart: formatDateFn(e.startDate, "yyyy-MM-dd"),
            dateEnd: formatDateFn(e.endDate, "yyyy-MM-dd"),
            label: e.label,
            value: e.value
        }, this.fetchData)
    }

    render() {
        return (
            <>
                <Card body>
                    <Row>
                        <Col xs="6">
                            <h2 className="small-title">Utilization</h2>
                        </Col>
                        <Col xs="6" className="text-end">
                            <TimeSpan onChange={this.onTimeChange} value={ this.state.value }></TimeSpan>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs="12">
                        <Table
                            headers={this.headers}
                            page={this.state.data}
                            sort={{ index: this.state.index, direction: this.state.direction }}
                            size={this.state.size}
                            index={this.state.page}
                            pages={this.state.pages}
                            onColumnClick={this.onColumnClick}
                            onPageChange={this.onPageChange}
                        />
                    </Col></Row>
                </Card>
                
                <Card body className="mt-3">
                    <Row>
                        <Col xs="2"></Col>
                        <Col xs="4" style={{fontWeight: "bolder"}} className="text-end"> <span>Time </span></Col>
                        <Col xs="6" style={{fontWeight: "bolder"}} className="text-end">{this.onMinutesFormat(this.state?.data[0]?.totalMinutes)}</Col>
                    </Row>
                    <Row>
                        <Col xs="2"></Col>
                        <Col xs="4" style={{fontWeight: "bolder"}} className="text-end"> <span>Value </span></Col>
                        <Col xs="6" style={{fontWeight: "bolder"}} className="text-end">{currency(this.state?.data[0]?.totalCharge)}</Col>
                    </Row>
                </Card>
            </>
        )
    }
}

Utilization.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        items: state.licensee.clients
    });
};

export default connect(mapStateToProps, { getEmployees })(Utilization);