import api from "../utils/api";
import {
  GET_EMPLOYEE, GET_EMPLOYEES, LOGOUT
} from "./types";
import { send as sendAlert } from "./alert";
import { valid } from "../utils/date";

export const save = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/employees/me`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/employees/i/${uuid}`);
        dispatch({
            type: GET_EMPLOYEE,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/employees/firm`);
        dispatch({
            type: GET_EMPLOYEES,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const table = (page, size, order, direction, dateStart, dateEnd, search="") => async (dispatch) => {
    try {
        const url = valid(dateStart) && valid(dateEnd) ? `/employees/l/p/${page}/s/${size}/o/${order}/${direction}/dt/${dateStart}/${dateEnd}/${search}` : `/employees/l/p/${page}/s/${size}/o/${order}/${direction}/${search}`;
        const res = await api.get(url);

        dispatch({
            type: GET_EMPLOYEES,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};
